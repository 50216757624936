export interface Form {
    formId: number
    formUID: string
    name: string
    questions: Array<Question>
    baseScore: number
    programId: number
    applicationTypeId: number
    intakeId: number
    bmpId: number
}

export type QuestionType = 'Checkbox' | 'Drop Down' | 'Radio Button' | 'Text'

export const ALL_QUESTION_TYPES: Array<QuestionType> = ['Checkbox', 'Drop Down', 'Radio Button', 'Text']

export type DescriptionRequiredType = 'No' | 'Yes' | 'Number'

export const ALL_DESCRIPTION_REQUIRED_TYPES: Array<DescriptionRequiredType> = ['No', 'Yes', 'Number']

export interface Question {
    questionId: number
    questionType: QuestionType
    questionTextEnglish: string
    questionTextFrench: string
    numberOfAnswers: number
    options: Array<Option>
    scoreCap?: number
    isMaxScoreOnly: boolean
    isIncludedOnReport: boolean
    isIncludedInPortal: boolean
}

export interface Option {
    answerId: number
    answerTextEnglish: string
    answerTextFrench: string
    nextQuestionId?: number
    isDescriptionRequired: DescriptionRequiredType
    isAllOfTheAbove: boolean
    isNoneOfTheAbove: boolean
    score?: number
}

export interface Responses {
    answers: Array<Answer>
    noAnswerProvided: Array<NoAnswer>
}

export interface Answer {
    answerId: number
    description?: string
}

export interface NoAnswer {
    questionId: number
}

interface AnswerMap {
    [answerId: number]: boolean
}

interface QuestionDetail {
    scoreCap?: number
    isMaxScoreOnly: boolean
    answerScores: Array<number>
}

interface QuestionMap {
    [questionId: number]: QuestionDetail
}

export interface QuestionScores {
    [questionId: number]: number
}

export function getFormScores(pif: Form, responses: Responses): QuestionScores {
    let questionMap: QuestionMap = {}
    let questionScores: QuestionScores = {}

    // we create an answer map so that we only have to walk over the pif tree once
    let answerMap = responses.answers.reduce((answerMap, answer) => {
        answerMap[answer.answerId] = true
        return answerMap
    }, {} as AnswerMap)

    // walk the pif tree, building up our questionMap
    pif.questions.forEach(q => {
        q.options.forEach(o => {
            if (answerMap[o.answerId] && o.score) {
                let questionDetail = questionMap[q.questionId]
                if (questionDetail) {
                    if (o.score) questionDetail.answerScores.push(o.score)
                }
                else {
                    questionDetail = { scoreCap: q.scoreCap, isMaxScoreOnly: q.isMaxScoreOnly, answerScores: [o.score] }
                    questionMap[q.questionId] = questionDetail
                }
            }
        })
    })

    // record our scores
    Object.keys(questionMap).forEach(questionId => {
        let questionDetail = questionMap[questionId as any as number]

        let questionScore = (questionDetail.isMaxScoreOnly) ?
            Math.max(...questionDetail.answerScores) :
            questionDetail.answerScores.reduce((sum, score) => sum + score)

        if (questionDetail.scoreCap && questionScore > questionDetail.scoreCap) questionScore = questionDetail.scoreCap

        questionScores[questionId as any as number] = questionScore
    })

    return questionScores
}