import React from 'react'
import { ALL_DESCRIPTION_REQUIRED_TYPES, DescriptionRequiredType, Option, QuestionType } from '../../models/Form'
import { AnswerMoved, AnswerRemoved, AnswerUpdated } from '../../logic/Form'
import { EditPifContext } from './EditPifContext'
import { Button, Form, FormGroup, Input, CustomInput, Label, Row, Col } from 'reactstrap'

import './Answer.scss'

export interface AnswerProps {
    answer: Option 
    questionIndex: number
    questionIds: Array<number>
    questionType: QuestionType
    answerIndex: number
    totalAnswers: number
}

enum SelectionBehaviour {
    Default, 
    AllOfTheAbove,
    NoneOfTheAbove
}

export interface AnswerState {
}

type AnswerProperty = keyof Option | 'sortOrder' | 'selectionBehaviour'

export class Answer extends React.Component<AnswerProps, AnswerState> {
    static contextType = EditPifContext
    context!: React.ContextType<typeof EditPifContext>

    constructor(props: AnswerProps) {
        super(props)
        this.state = {}
    }

    handleIndexChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        let op = new AnswerMoved(this.props.questionIndex, this.props.answerIndex, e.target.valueAsNumber - 1)
        this.context.updatePif(op)
    }

    handleAnswerDeleted = () => {
        let op = new AnswerRemoved(this.props.questionIndex, this.props.answerIndex)
        this.context.updatePif(op)
    } 

    updateAnswerWrapper = (answerUpdater: { (answer: Option): Option }) => {
        let answer = { ...this.props.answer }

        let updatedAnswer = answerUpdater(answer)
        let op = new AnswerUpdated(updatedAnswer)

        this.context.updatePif(op)
    }

    handleTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            answer.answerTextEnglish = e.target.value
            return answer
        })
    }

    handleTextChangedFrench = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            answer.answerTextFrench = e.target.value
            return answer
        })
    }

    handleSelectionBehaviourChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            switch (parseInt(e.target.value)) {
                case SelectionBehaviour.Default:
                    answer.isAllOfTheAbove = false
                    answer.isNoneOfTheAbove = false
                    break;
                case SelectionBehaviour.AllOfTheAbove:
                    answer.isAllOfTheAbove = true 
                    answer.isNoneOfTheAbove = false
                    break;
                case SelectionBehaviour.NoneOfTheAbove:
                    answer.isAllOfTheAbove = false
                    answer.isNoneOfTheAbove = true
                    break;
            }
            return answer
        })
    }

    handleScoreChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            answer.score = e.target.valueAsNumber
            return answer
        })
    }

    handleNextQuestionNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            if (e.target.valueAsNumber) answer.nextQuestionId = this.props.questionIds[e.target.valueAsNumber - 1]
            return answer
        })
    }

    // prevent invalid next question number via direct typing
    // (spinner clicking already handled by input's min/max props)
    handleNextQuestionNumberBlurred = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.valueAsNumber && e.target.valueAsNumber < this.props.questionIndex + 3) {
            this.updateAnswerWrapper(answer => {
                delete answer.nextQuestionId
                return answer
            })
        }
    }

    handleDescriptionRequiredChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.updateAnswerWrapper(answer => {
            answer.isDescriptionRequired = e.target.value as DescriptionRequiredType
            return answer
        })
    }

    private idFor = (property: AnswerProperty) => `answer${this.props.answer.answerId}_${property}`

    render() {
        let { answer } = this.props

        let selectionBehaviourEnabled: boolean = this.props.questionType == "Checkbox"

        let selectionBehaviourFormGroup = (
            <FormGroup tag='fieldset'>
                <Label>Selection behaviour: </Label>
                <FormGroup check>
                    <Label check>
                        <Input type='radio'
                            name={this.idFor('selectionBehaviour')}
                            value={SelectionBehaviour.Default}
                            checked={!(answer.isAllOfTheAbove || answer.isNoneOfTheAbove)}
                            onChange={this.handleSelectionBehaviourChanged}
                            disabled={!selectionBehaviourEnabled} />
                        Default
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type='radio'
                            name={this.idFor('selectionBehaviour')}
                            value={SelectionBehaviour.AllOfTheAbove}
                            checked={answer.isAllOfTheAbove}
                            onChange={this.handleSelectionBehaviourChanged}
                            disabled={!selectionBehaviourEnabled} />
                        All of the Above
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type='radio'
                            name={this.idFor('selectionBehaviour')}
                            value={SelectionBehaviour.NoneOfTheAbove}
                            checked={answer.isNoneOfTheAbove}
                            onChange={this.handleSelectionBehaviourChanged}
                            disabled={!selectionBehaviourEnabled} />
                        None of the Above
                    </Label>
                </FormGroup>
            </FormGroup>
        )

        let nextQuestionEnabled = this.props.questionIndex < (this.props.questionIds.length - 2)

        let nextQuestionIndex = this.props.answer.nextQuestionId && this.props.questionIds.indexOf(this.props.answer.nextQuestionId)

        let nextQuestionNumberString = (!nextQuestionEnabled || (nextQuestionIndex == undefined) || nextQuestionIndex == -1) ?
            '' : (nextQuestionIndex + 1).toString()

        return (
            <div className='answer-builder border mb-3 p-2'>
                <Row>
                    <Col md={9}>
                        <Form inline>
                            <FormGroup>
                                <Label for={this.idFor('sortOrder')} className='mr-2'>Answer: </Label>
                                <Input id={this.idFor('sortOrder')}
                                    type='number'
                                    min={1} max={this.props.totalAnswers}
                                    value={this.props.answerIndex + 1}
                                    onChange={this.handleIndexChanged}
                                    style={{ width: '50%' }} />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col>
                        <Button close onClick={this.handleAnswerDeleted}></Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup>
                            <Label for={this.idFor('answerTextEnglish')}>English Text: </Label>
                            <Input id={this.idFor('answerTextEnglish')} rows={2} type='textarea' value={answer.answerTextEnglish} onChange={this.handleTextChanged} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        {selectionBehaviourFormGroup}
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup>
                            <Label for={this.idFor('answerTextFrench')}>French Text: </Label>
                            <Input id={this.idFor('answerTextFrench')} rows={2} type='textarea' value={answer.answerTextFrench} onChange={this.handleTextChangedFrench} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <Form className='mb-2'>
                            <FormGroup row>
                                <Label for={this.idFor('score')} md={6}>Score: </Label>
                                <Col md={6}>
                                    <Input id={this.idFor('score')}
                                        type='number'
                                        min={0}
                                        value={this.props.answer.score || ''}
                                        onChange={this.handleScoreChanged} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for={this.idFor('nextQuestionId')} md={6}>Next Question Number: </Label>
                                <Col md={6}>
                                    <Input id={this.idFor('nextQuestionId')}
                                        type='number'
                                        min={this.props.questionIndex + 3}
                                        max={this.props.questionIds.length}
                                        value={nextQuestionNumberString}
                                        disabled={!nextQuestionEnabled}
                                        onChange={this.handleNextQuestionNumberChanged}
                                        onBlur={this.handleNextQuestionNumberBlurred}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <FormGroup row>
                    <Label for={this.idFor('isDescriptionRequired')} md={4}>Description is Required: </Label>
                    <Col md={4}>
                        <Input id={this.idFor('isDescriptionRequired')} type='select' name={this.idFor('isDescriptionRequired')} value={this.props.answer.isDescriptionRequired} onChange={this.handleDescriptionRequiredChanged} label='Description is required' >
                            {ALL_DESCRIPTION_REQUIRED_TYPES.map(isDescriptionRequired => <option key={isDescriptionRequired}>{isDescriptionRequired}</option>)}
                        </Input>
                    </Col>
                </FormGroup>
            </div>
        )
    }
}